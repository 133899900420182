import {Message} from 'element-ui'
import {Dialog} from 'vant'
import router from '../router'

export default {
  p_warning(msg) {
    var arr = Object.keys(msg);
    var message = "";
    for (var i = 0; i < arr.length; i++) {
      message = arr[i];
      Message({
        dangerouslyUseHTMLString: true,
        message: message,
        type: 'warning'
      });
    }

  },
  errorMsg(msg) {
    if (msg) {
      Dialog({
        dangerouslyUseHTMLString: true,
        message: msg,
      });
    }
  },
  m_warning(msg) {
    if (msg) {
      var msgmap = Object.entries(msg);
      var msgs = [];
      for (var [key, value] of msgmap) {
        msgs.push(key);
      }
      var message = msgs.join("\r\n");
      Dialog({
        dangerouslyUseHTMLString: true,
        message: message,
      });
    }
  },
  getBy(array, key, value) {
    return array.find(function (item) {
      return item[key] === value;
    });
  },
  callPhone(phoneNumber) {
    window.location.href = 'tel://' + phoneNumber
  },
  toThousands(num) {
    var result = '', counter = 0;
    num = (num || 0).toString();
    for (var i = num.length - 1; i >= 0; i--) {
      counter++;
      result = num.charAt(i) + result;
      if (!(counter % 3) && i != 0) {
        result = ',' + result;
      }
    }
    return result;
  },
  getIpholderId(values){
    let url = window.location.pathname;
    let ipholderPath = null;
    if (url){
      ipholderPath = url.substring(1,url.lastIndexOf("/"))
    }
    return values + '_'+ ipholderPath;
  },
}
