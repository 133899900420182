<template>
  <div style="margin-left: 15%;margin-right: 15%;">
    <div v-if="loginReturnFlg" class="ta_l mt_10" style="margin-top: 30%;margin-bottom: 15%;">
      <van-image class="icon btn_return" @click="returnPage"
                 :src="require('../../assets/Sprint2/btn/btn_return.png')"></van-image>
    </div>
    <div v-else class="ta_r mt_10" style="margin-top: 30%;margin-bottom: 15%;">
      <van-image class="icon close-pop" @click="onlyClosePop"
                 :src="require('../../assets/Sprint2/btn/btn_close.gif')"></van-image>
    </div>
    <div class="ta_c">
      <span class="span-login-pop">{{$t(`login.0001`)}}</span>
    </div>
    <div class="ta_c" style="margin-top: 10%;">
      <van-button class="span-login-pop" round style="color: white;background: #4ca9e7;width: 100%"
                  @click="twitterLogin"
                  :icon="require('../../assets/Sprint2/logo/twitter.png')">{{$t(`login.0002`)}}
      </van-button>
    </div>
    <div class="ta_c mt_10">
      <van-button class="span-login-pop" round style="color: white;background: #4d7fc1;width: 100%;"
                  v-google-signin-button="clientId"
                  :icon="require('../../assets/Sprint2/logo/google.png')">{{$t(`login.0003`)}}
      </van-button>
    </div>
    <div class="mt_10" style="padding-top: 2%;padding-bottom: 2%;">
      <van-divider :style="{ color: 'black', borderColor: 'black',fontWeight:'600'}">{{$t(`login.0004`)}}</van-divider>
    </div>
    <van-form @submit="onSubmit" ref="loginForm" show-error-message :show-error="false" :scroll-to-error="true">
      <div class="mt_10">
        <van-field style="border: 1px solid;" v-model="loginId" name="loginId"
                   :placeholder="$t(`login.0005`)"
                   :rules="[{ validator: validator }]"
                   :error-message="loginIdErrorMsg"
        />
      </div>
      <div class="mt_10">
        <van-field
          style="border: 1px solid;"
          v-model="password"
          type="password"
          name="password"
          placeholder="Password"
          :rules="[{ required: true, message: $t(`login.0006`) }]"
        />
      </div>
      <div class="mt_10">
        <van-button round block native-type="submit"
                    style="width:100%;background-image: linear-gradient(to right, #1b93a0, #78dbd6);">
          <span class="span-login-pop" style="color: white;">{{$t(`login.0007`)}}</span>
        </van-button>
      </div>
    </van-form>
    <div class="ta_r" style=" margin-top: 3%;color: rgb(27, 147, 160);font-size: 12px;font-weight: 600;"
         @click="passForgetClick()">{{$t(`login.0008`)}}
    </div>
    <div class="ta_c" style="margin-top: 15%;">
      <span class="span-login-pop" style="text-decoration: underline;" @click="addUserClick()">{{$t(`login.0009`)}}</span>
    </div>
  </div>
</template>

<script>
  import hello from 'hellojs/dist/hello.all.js'

  export default {
    name: "login",
    data() {
      return {
        clientId: this.global.ev_variables.get(process.env.EV_TYPE).GOOGLE_KEY,
        //ログイン
        loginId: '',
        password: '',

        loginIdErrorMsg: '',
        showFlg: false,

        twsUrl: '',
        ipholderCode: 0,
      }
    },
    props: {loginReturnFlg: Boolean},
    methods: {
      validator(val) {
        if (!val) {
          this.loginIdErrorMsg = this.$t("newuser.0005");
          return false;
        }

        if (!this.$validator.isEmail(val)) {
          this.loginIdErrorMsg = this.$t("newuser.0006");
          return false;
        }

        this.loginIdErrorMsg = '';
        return true;
      },

      async checkRecaptchaToken(values, useType) {
        let res;
        await this.$store
          .dispatch("checkRecaptchaTokenStore", {
            reCAPTCHAToken: values,
            useType: useType
          })
          .then((result) => {
            res = result.data.data.recaptchaFlg
          })
          .catch((err) => {
            res = false;
          });
        return res;
      },
      async getreCAPTCHA() {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()
        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login')
        return token;
      },
      async googleLogin(idToken){
        // The ID token you need to pass to your backend:
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t('login.0027'),
        });
        const reCAPTCHAToken = await this.getreCAPTCHA();
        const res = await this.checkRecaptchaToken(reCAPTCHAToken, "102");
        if (!res){
          //done
          return ;
        }
        var params = {
          accessToken: idToken,
          ipholderCode: this.ipholderCode,
        };
        this.$store
          .dispatch("googleLoginStore",  [this.$qs.stringify(params), {reCAPTCHAToken: reCAPTCHAToken}])
          .then((result) => {
            if (localStorage.getItem(this.$common.getIpholderId("ls_info"))) {
              var userData = JSON.parse(localStorage.getItem(this.$common.getIpholderId("ls_info")))
              if (userData.mailAuthFlg) {
                this.$emit("closeLoginPop");
              } else {
                this.$emit("maillVerificationClick")
              }
            }
            //badge  取得、
            this.$store.commit("SET_BADGE");
            this.$bus.$emit('changeLogin', false);
            this.$toast.clear();
          })
          .catch(() => {
            this.$toast.clear();
          });
      },
      OnGoogleAuthSuccess(googleUser) {
        const id_token = googleUser.getAuthResponse().id_token;
        this.googleLogin(id_token);
      },
      OnGoogleAuthFail(error) {
        console.log(error)
        this.$toast.clear();
      },

      onSubmit(values) {
        let params = {
          loginId: this.loginId,
          password: this.password,
          ipholderCode: this.ipholderCode,
        }
        this.$emit('onSubmit', params)
      },
      passForgetClick() {
        this.$emit('passForgetClick', '11')
      },
      addUserClick() {
        this.$emit('addUserClick', '11')
      },
      closeLoginPop() {
        this.$emit('closeLoginPop', '11')
      },
      onlyClosePop() {
        this.$nextTick(() => {
          this.loginId = null;
          this.password = null;
          this.loginIdErrorMsg = null;
          this.$refs.loginForm.resetValidation('loginForm'); //等弹窗里的form表单的dom渲染完在执行this.$refs.staffForm.resetFields()，去除验证
        });
        this.$emit('onlyClosePop')
      },
      returnPage() {
        this.$emit('returnPage')
      },
      twws() {
        hello.init({
          twitter: this.global.ev_variables.get(process.env.EV_TYPE).TWITTER_KEY
        }, {
          scope: 'email',
          redirect_uri: this.global.ev_variables.get(process.env.EV_TYPE).TWITTER_REDIRECT_URI,
          oauth_proxy: this.global.ev_variables.get(process.env.EV_TYPE).TWITTER_OAUTH_PROXY,
          force: true,
        });
      },
      twitterLogin() {
        console.log(this.$store.getters.reCAPTCHAToken)
        var that = this;
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t('login.0027'),
        });
        const reCAPTCHAToken = this.$store.getters.reCAPTCHAToken;
        hello('twitter').login().then(function (res) {//'facebook'登录，该字段可以换成你想要某个第三方应用登录的字段
          let params ={
            accessToken: res.authResponse.access_token,
            ipholderCode: that.ipholderCode,
          }
          that.$store
            .dispatch("twitterLoginStore", [that.$qs.stringify(params), {reCAPTCHAToken: reCAPTCHAToken}])
            .then((res) => {
              that.$toast.clear();
              var userInfo = localStorage.getItem(that.$common.getIpholderId("ls_info"));
              if (userInfo) {
                var userData = JSON.parse(userInfo);
                if (userData.mailAuthFlg) {
                  that.$emit("closeLoginPop");
                } else {
                  that.$emit("maillVerificationClick")
                }
              }
              //badge  取得、
              that.$store.commit("SET_BADGE");
            })
            .catch(() => {
              that.$toast.clear();
            });
        }, function (e) {
          console.log('Signin error: ' + JSON.stringify(e));
          that.$toast.clear();
          //一般出错均为开发者平台没有配好设置
        });
      }
    },
    mounted() {
      this.twws();
    },
    created() {
      if (this.$route.params && this.$route.params.ipholderCode) {
        this.ipholderCode = this.$route.params.ipholderCode;
      }
    }
  }
</script>

<style>
  .twitter-signin {
    border: solid 1px #ddd;
    margin-top: 10px;
    width: 111px;
    height: 36px;
    font-size: 13px;
    line-height: 34px;
    background-color: #4285f4;
    color: #FFF;
    padding-left: 8px;
    cursor: pointer;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
    text-align: center;
  }

  #homeLink {
    cursor: pointer;
    line-height: 23px;
    margin-top: 9px;
    margin-bottom: 20px;
  }

  .wbtn {
    width: 210px !important;
  }
</style>
