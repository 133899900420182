<template>
  <div style="margin-left: 15%;margin-right: 15%;">
    <div class="ta_r mt_10" style="margin-top: 30%;margin-bottom: 15%;">
      <van-image class="icon close-pop" @click="closeBtn"
                 :src="require('../../assets/Sprint2/btn/btn_close.gif')"></van-image>
    </div>
    <div class="span-login-pop ta_c" style="margin-top: 80%;color: black;">
      <span>{{$t('mypage.0057')}}</span><br><br>
      <span style="font-size: 14px;">{{$t('mypage.0058')}}</span>
    </div>
    <div style="margin-top: 10%;color: #1b93a0;">
      <van-button round block @click="sendMail()"
                  style="width:100%;background-image: linear-gradient(to right, #1b93a0, #78dbd6);"
                  :disabled="accreditationTime != this.global.accreditationTime">
        <template v-if="accreditationTime != this.global.accreditationTime">
          <span class="span-login-pop" style="color: white;">{{accreditationTime + $t('mypage.0059')}}</span>
        </template>
        <template v-else>
          <span class="span-login-pop" style="color: white;">{{$t('mypage.0060')}}</span>
        </template>

      </van-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "mailboxVerificationPop",
    data() {
      return {
        accreditationTime: this.global.accreditationTime,
        clock: null,
        mailTimeTitle: '',
        ipholderCode:"",
      }
    },
    methods: {
      sendMail() {
        this.$store
          .dispatch("authMailRequestStore", this.$qs.stringify({ipholderCode: this.ipholderCode}))
          .then((result) => {
            localStorage.setItem(this.$common.getIpholderId("accreditationTime"), JSON.stringify(Date.parse(new Date()) / 1000));
            this.accreditationTimeFun();
            this.closeBtn();
            this.$toast({
              message: this.$t("emsg.0001"),
              position: 'bottom',
              duration: 3000,
            })
          })
          .catch(() => {
          });
      },
      accreditationTimeFun() {
        this.clock = window.setInterval(() => {
          if (this.accreditationTime == 1) {
            this.accreditationTime = this.global.accreditationTime;
            clearInterval(this.clock);
          } else {
            this.accreditationTime--;
          }
        }, 1000)
      },
      closeBtn() {
        this.$emit('closePop');
        this.$bus.$emit('changeLogin', false);
      },
    },
    mounted() {
      var newUserTime = localStorage.getItem(this.$common.getIpholderId("accreditationTime"))
      if (newUserTime && JSON.parse(newUserTime)) {
        var times = (Date.parse(new Date()) / 1000) - JSON.parse(newUserTime);
        if (times < this.global.accreditationTime) {
          this.accreditationTime = this.global.accreditationTime - times;
          this.accreditationTimeFun();
        }else {
          this.accreditationTime = this.global.accreditationTime;
        }
      }

      if (this.$route.params  && this.$route.params.ipholderCode) {
        this.ipholderCode = this.$route.params.ipholderCode;
      }
    },
  }
</script>

<style scoped>

</style>
