import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import event from './modules/event'
import login from  './modules/login'
import store from './modules/store'
import collection from  './modules/collection'

Vue.use(Vuex);

const storeApp = new Vuex.Store({
  modules: {
    app,
    event,
    login,
    store,
    collection,
  },
  getters,
});

export default storeApp
