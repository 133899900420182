function isEmail(s) {
  var patrn = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  if (!patrn.exec(s)) {
    return false;
  }else {
    return true;
  }
}

module.exports = {
  isEmail,
};
