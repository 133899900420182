const ipH_data = [
  {
    ipholderCode: 'crocodile',
    noticeList: [
      {
        title: '桜木つぐみ生誕祭をlivesolaを用いて開催決定！',
        noticeTime: '2022/02/06'
      },
      {
        title: 'livesolaにて桜木つぐみ生誕祭の限定商品が販売開始！詳しくはストアページへ！',
        noticeTime: '2022/01/31'
      },
    ],
  },
  {
    ipholderCode: 'himeoke',
    noticeList: [
      {
        title: 'ひめもすオーケストラのオリジナルビデオメッセージ販売開始！',
        noticeTime: '2022/03/12'
      },
    ],
  },
  {
    ipholderCode: 'unicask',
    noticeList: [
      {
        title: 'livesola' + "'" + 's membership registration is now open! Double your chances of getting NFTs by registering for livesola, and recieve double the amount of hearts!',
        noticeTime: '2021/09/30'
      },
      {
        title: 'A few lucky participants will be able to win some NFTs during the livestream!',
        noticeTime: '2021/09/21'
      },
      {
        title: 'Japan' + "'" + 's UniCask and UK' + "'" + 's Kingsbury will collaborate to hold a livestream event!',
        noticeTime: '2021/09/15'
      },
    ],
  },
  {
    ipholderCode: 'recika',
    noticeList: [
      {
        title: 'レシカ１０月定例イベントのショップをオープン！「ストア」ページより購入可能！',
        noticeTime: '2021/10/22'
      },
      {
        title: 'レシカ１０月定例イベントをlivesolaを用いて開催決定！',
        noticeTime: '2021/10/15'
      },
    ],
  },
  {
    ipholderCode: 'discoverjapan',
    noticeList: [
      {
        title: 'UMAMI COLA×Discover Japanオリジナルクラフトコーラづくりライブ配信が開催',
        noticeTime: '2022/01/17'
      },
    ],
  },
];

let ev_variables = new Map();
ev_variables.set("dev", {
  GOOGLE_KEY: '357047588518-7s46a1tdteg719se45m3iel4i6a6q53c.apps.googleusercontent.com',
  TWITTER_KEY:'CyG9OSovJOmjzdcZ9l2D2l2ik',
  TWITTER_REDIRECT_URI:'http://localhost:5556/redirect',
  TWITTER_OAUTH_PROXY:'https://livesola-prod.recika.io/api/proxy',
  VUE_APP_GA_ID: '',
  RECAPTCHA_PUBLIC_KEY: '6LekGpIbAAAAAGUwutyVK8--Y29XXWZrwEhZe32t',
});
ev_variables.set("stg", {
  GOOGLE_KEY: '357047588518-7s46a1tdteg719se45m3iel4i6a6q53c.apps.googleusercontent.com',
  TWITTER_KEY:'CyG9OSovJOmjzdcZ9l2D2l2ik',
  TWITTER_REDIRECT_URI:'https://livesola-prod.recika.io/redirect',
  TWITTER_OAUTH_PROXY:'https://livesola-prod.recika.io/api/proxy',
  VUE_APP_GA_ID: 'UA-193786910-4',
  RECAPTCHA_PUBLIC_KEY: '6LekGpIbAAAAAGUwutyVK8--Y29XXWZrwEhZe32t',
});
ev_variables.set("liver", {
  GOOGLE_KEY: '357047588518-7s46a1tdteg719se45m3iel4i6a6q53c.apps.googleusercontent.com',
  TWITTER_KEY:'CyG9OSovJOmjzdcZ9l2D2l2ik',
  TWITTER_REDIRECT_URI:'https://livesola-liver.recika.io/redirect',
  TWITTER_OAUTH_PROXY:'https://livesola-liver.recika.io/api/proxy',
  VUE_APP_GA_ID: '',
  RECAPTCHA_PUBLIC_KEY: '6LekGpIbAAAAAGUwutyVK8--Y29XXWZrwEhZe32t',
});
ev_variables.set("preview", {
  GOOGLE_KEY: '357047588518-7s46a1tdteg719se45m3iel4i6a6q53c.apps.googleusercontent.com',
  TWITTER_KEY:'CyG9OSovJOmjzdcZ9l2D2l2ik',
  TWITTER_REDIRECT_URI:'https://livesola-preview.recika.io/redirect',
  TWITTER_OAUTH_PROXY:'https://livesola-preview.recika.io/api/proxy',
  VUE_APP_GA_ID: '',
  RECAPTCHA_PUBLIC_KEY: '6LekGpIbAAAAAGUwutyVK8--Y29XXWZrwEhZe32t',
});
ev_variables.set("demo", {
  GOOGLE_KEY: '357047588518-7s46a1tdteg719se45m3iel4i6a6q53c.apps.googleusercontent.com',
  TWITTER_KEY:'CyG9OSovJOmjzdcZ9l2D2l2ik',
  TWITTER_REDIRECT_URI:'https://livesola-dev.recika.io/redirect',
  TWITTER_OAUTH_PROXY:'https://livesola-dev.recika.io/api/proxy',
  VUE_APP_GA_ID: '',
  RECAPTCHA_PUBLIC_KEY: '6LekGpIbAAAAAGUwutyVK8--Y29XXWZrwEhZe32t',
});
ev_variables.set("prod", {
  GOOGLE_KEY: '311064604391-iujumsscuaiu49tqshaq7vhcijs756us.apps.googleusercontent.com',
  TWITTER_KEY:'UhRM36aL0cNNrPZESsGdZj8T5',
  TWITTER_REDIRECT_URI:'https://livesola.com/redirect',
  TWITTER_OAUTH_PROXY:'https://livesola.com/api/proxy',
  VUE_APP_GA_ID: '',
  RECAPTCHA_PUBLIC_KEY:'6LfpetsbAAAAAEz4DecGIS79ZeEXkp0Q8fPV5sBs',
});
export default {
  passwordForgetTime: 60,
  newUserTime: 60,
  accreditationTime: 60,
  ipH_data: ipH_data,
  ev_variables: ev_variables,
}




















































































































































