<template>
  <div class="full">
    <van-row style="background: white;padding: 15px;" class="ta_c">
      <van-col span="13">
        <img class="icon footerImgHeight" :src="require('../../assets/Sprint2/head/head_logo.gif')"></img>
      </van-col>
      <!-- //ログイン　ボタン-->
      <van-col span="8" style=" z-index: 7;">
          <img @click="loginClick()" v-if="btnFlag && !(isPreviewFlg == 1)" class="icon footerImgHeight" :src="require('../../assets/Sprint2/head/head_login_' + this.$store.getters.language_lang + '.gif')"></img>
      </van-col>
      <van-col span="3" @click="changeLanguage()" v-if="!(isPreviewFlg == 1)">
          <img class="icon footerImgHeight" :src="require('../../assets/Sprint2/' + this.$store.getters.language_lang + '.png')"></img>
      </van-col>
    </van-row>
    <div class="page-container5" v-if="nowRouter == 'home'">
      <template v-if="mainEvent">
        <div class="event-home-img" style="position: relative;" @click="eventClick(mainEvent)"
             :style="{pointerEvents: isPreviewFlg == 1 ? `none`:``}">
          <img class="img_event img1" style="height: auto;"
               :src="mainEvent.imageUrl">
          <div class="event-home-title">
            <div style="margin-left: 10px;margin-top: 10px;margin-bottom: 10px;">
              <div class="mt_10">
                <van-image class="iconHeader" style="width: 3.5rem;"
                           :src="require('../../assets/Sprint2/home/live.png')"></van-image>
                <template v-if="mainEvent.needTicket">
                  <van-image class="iconHeader" style="width: 4.5rem;"
                             :src="require('../../assets/Sprint2/home/tickets.png')"></van-image>
                </template>
                <template v-else>
                  <van-image class="iconHeader" style="width: 3rem;"
                             :src="require('../../assets/Sprint2/home/free.png')"></van-image>
                </template>
              </div>
              <div class="ellipsis">{{mainEvent.eventTitle}} {{mainEvent.startDate}}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="event-home-img" style="position: relative;">
          <img class="img_event img1" style="height: auto;" :src="noEvent">
        </div>
      </template>
    </div>

    <van-row v-if="nowRouter == 'store' || nowRouter == 'collection'">
      <template v-if="mainEvent && !(isPreviewFlg == 1)">
          <van-image :src="require('../../assets/Sprint2/head/banner_' + this.$store.getters.language_lang + '.jpg')" @click="eventClick(mainEvent)"></van-image>
      </template>
    </van-row>

    <AppBase></AppBase>
    <Footer></Footer>
  </div>
</template>

<script>
  import AppBase from "./AppBase";
  import Footer from "./Footer";
  import {setup} from "../../i18n/locales/index";

  export default {
    name: "layout",
    data() {
      return {
        noEvent: require('../../assets/Sprint2/crocodaile.jpg'),
        btnFlag: true,
        mainEvent: {},
        nowRouter: '',
        ipholderCode: 0,
      }
    },
    computed: {
      isPreviewFlg() {
        return this.$store.state.app.isPreviewFlg
      }
    },
    components: {
      AppBase,
      Footer,
    },
    // 监听,当路由发生变化的时候执行
    watch: {
      $route: {
        handler: function (val, oldVal) {
          if (val.name){
            this.nowRouter = val.name;
          }
        },
        // 深度观察监听
        deep: true,
        immediate: true
      }
    },
    methods: {
      changeLanguage(){
        let lang;
        if (this.$store.getters.language_lang === 'ja'){
          lang = 'en'
        }else if (this.$store.getters.language_lang === 'en'){
          lang = 'zh'
        }else {
          lang = 'ja'
        }
        this.$i18n.locale = lang;
        setup(lang);
        location.reload() // 为了重新实例化vue-router对象，避免一些bug
      },
      getEventList() {
        let params = {ipholderCode: this.ipholderCode};
        this.$store.dispatch("getEventListStore", params)
          .then(result => {
            this.mainEvent = result.data.data.topList[0];
            this.$bus.$emit('getHomeData', result.data.data.futureList);
            this.nowEvent(result.data.data.futureList);
            this.endEvent(result.data.data.topList);
            this.noEvent = result.data.data.ipholderPhoto;
          })
          .catch(() => {
          });
      },
      endEvent(value) {
        if (value[0] && value[0].intervalEndTime > 0 && value[0].intervalEndTime < 2147483647) {
          setTimeout(() => {
            this.getEventList();
          }, value[0].intervalEndTime);
        }
      },
      nowEvent(value) {
        if (value[0] && value[0].intervalTime > 0 && value[0].intervalTime < 2147483647) {
          setTimeout(() => {
            this.getEventList();
          }, value[0].intervalTime);
        }
      },
      loginClick() {
        let params = {
          popName: 'login',
          routerPath: 'home',
          routerParams: {}
        }
        this.$bus.$emit('login', params)
      },
      eventClick(values) {
        let params = {
          ipholderCode: this.ipholderCode,
          eventId: values.eventId
        };
        this.$store.dispatch("joinEventCheckStore", this.$qs.stringify(params))
          .then(result => {
            //pageCode:{
            //loginR -> login  画面
            //loginA -> loginなし画面
            //ProductBuy -> 購入画面に遷移する
            //cardList -> カード画面に遷移する
            //}
            if (result.data.data.pageCode == 'LoginA') {
              let params = {
                popName: 'startWithout',
                routerPath: "event",
                routerParams: {
                  eventId: values.eventId,
                }
              };
              this.$bus.$emit('login', params);
            } else if (result.data.data.pageCode == 'LoginR') {
              let params = {
                popName: 'login',
                routerPath: 'home',
                routerParams: {}
              };
              this.$bus.$emit('login', params);
            } else if (result.data.data.pageCode == 'ProductBuy') {
              let params = {
                popName: 'needTicket',
                routerPath: 'store',
                routerParams: {}
              }
              this.$bus.$emit('login', params)
            } else if (result.data.data.pageCode == 'CardList') {
              this.$router.push({
                path: "event",
                query: {
                  eventId: values.eventId,
                }
              })
            }
          })
          .catch(() => {
          });
      },
    },
    mounted() {
      this.$bus.$on('changeLogin', val => {
        this.btnFlag = val;
      })
    },
    created() {
      if (localStorage.getItem(this.$common.getIpholderId("ls_login_flg"))) {
        if (localStorage.getItem(this.$common.getIpholderId("ls_login_flg")) == 'true' || JSON.parse(localStorage.getItem(this.$common.getIpholderId("ls_login_flg")))) {
          this.btnFlag = false;
        } else {
          this.btnFlag = true;
        }
      } else {
        this.btnFlag = true;
      }

      if (this.$route.params && this.$route.params.ipholderCode) {
        this.ipholderCode = this.$route.params.ipholderCode;
        this.getEventList();
      }
    },
    destroyed() {
    },
  };
</script>

<style type="" scoped>
  .full {
    height: auto;
    width: 100%;
  }

  .footerImgHeight {
    height: 2rem;
  }
</style>
