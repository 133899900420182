import Vue from 'vue'// 引入vue实例
import VueI18n from 'vue-i18n'// 引入vue-i18n多语言包

// 引入element ui自带语言包
import { Locale } from 'vant';
import enLocale from 'vant/es/locale/lang/en-US';
import jaLocale from 'vant/es/locale/lang/ja-JP';
import zhLocale from 'vant/es/locale/lang/zh-CN.js';

Vue.use(VueI18n)


const DEFAULT_LANG = 'ja'// 默认语言为日文
const LOCALE_KEY = 'lang' // localStorage来存放的key

const locales = {// ja.json以及en.json
  ja: require('./ja.json'),
  en: require('./en.json'),
  zh: require('./zh.json')
};

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales,
});

const UIlocales = {
  ja: jaLocale,
  en: enLocale,
  zh: zhLocale,
};

// 通过判断lang语言标志符来return先对应的语言
const setUIlocales = lang => { //切换语言的函数，lang为语言标识，en或者ja
  switch (lang) {
    case 'ja':
      return UIlocales.ja;
    case 'en':
      return UIlocales.en;
    case 'zh':
      return UIlocales.zh;
  }
};
// 若lang有值，那么存入localStorage中，key为LOCALE_KEY,value为lang。
export const setup = lang => {
  if (lang == undefined) {
    lang = window.localStorage.getItem(LOCALE_KEY)
    if (locales[lang] == undefined) {
      lang = DEFAULT_LANG
    }
  }
  window.localStorage.setItem(LOCALE_KEY, lang)
  Object.keys(locales).forEach(item => {
    document.body.classList.remove('lang-${item}')
  })
  document.body.classList.add('lang-${lang}')
  document.body.setAttribute('lang', lang)

  Vue.config.lang = lang
  i18n.locale = lang
  Locale.use(lang,setUIlocales(lang)) // vant
}

setup();
export default i18n
