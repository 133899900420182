const getters = {
  pageSkip: state => state.app.pageSkip,
  //是否登录判断
  isLogin: state => state.app.isLogin,
  reCAPTCHAToken: state => state.app.reCAPTCHAToken,
  isPreviewFlg: state => state.app.isPreviewFlg,
  language_lang: state => state.app.language_lang,
  badge: state => state.storeApp.badge,
};
export default getters
