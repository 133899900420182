<template>
  <div style="margin-left: 15%;margin-right: 15%;">
    <div class="ta_l mt_10" style="margin-top: 30%;margin-bottom: 15%;">
      <van-image class="icon btn_return" @click="closeNewUser"
                 :src="require('../../assets/Sprint2/btn/btn_return.png')"></van-image>
    </div>

    <div class="ta_c" style="margin-top: 60%;">
      <span class="span-login-pop">{{$t('newuser.0001')}}</span>
      <div class="ta_c mt_10">
        <span class="span-login-pop" style="font-size: 12px;">{{$t('newuser.0002')}}</span>
      </div>
    </div>

    <van-form @submit="submitForm" style="margin-top: 15%;" show-error-message :show-error="false"
              :scroll-to-error="true">
      <div class="mt_10">
        <van-field style="border: 1px solid;" v-model="addUserForm.loginId" name="loginId"
                   placeholder="ID(メールアドレス)"
                   :rules="[{ validator:loginIdValidator }]"
                   :error-message="errForm.loginIdErrorMsg"
        />
      </div>

      <div class="mt_10" style="margin-top: 15%;">

        <van-button round block native-type="submit"
                    style="width:100%;background-image: linear-gradient(to right, #1b93a0, #78dbd6);"
                    :disabled="userTime != 60">
          <template v-if="userTime != 60">
            <span class="span-login-pop" style="color: white;">{{userTime +$t('newuser.0003')}}</span>
          </template>
          <template v-else>
            <span class="span-login-pop" style="color: white;">{{$t('newuser.0004')}}</span>
          </template>

        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  export default {
    name: "newUser",
    data() {
      return {
        addUserForm: {
          loginId: "",
          ipholderCode:"",
        },
        errForm: {
          loginIdErrorMsg: "",
        },
        pdfUrl: 'static/public/pdf/livesola利用規約.pdf',
        privacyPolicyUrl:'static/public/pdf/livesolaプライバシーポリシー.pdf',
      }
    },
    props: ['userTime'],
    methods: {
      loginIdValidator(val) {
        if (!val) {
          this.errForm.loginIdErrorMsg = this.$t("newuser.0005");
          return false;
        }
        if (!this.$validator.isEmail(val)) {
          this.errForm.loginIdErrorMsg = this.$t("newuser.0006");
          return false;
        }
        this.errForm.loginIdErrorMsg = '';
        return true;
      },
      async checkRecaptchaToken(values, useType) {
        let res;
        await this.$store
          .dispatch("checkRecaptchaTokenStore", {
            reCAPTCHAToken: values,
            useType: useType
          })
          .then((result) => {
            res = result.data.data.recaptchaFlg
          })
          .catch((err) => {
            res = false;
          });
        return res;
      },
      async getreCAPTCHA(){
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()
        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login')
        return token;
      },
      async submitForm() {
        const reCAPTCHAToken = await this.getreCAPTCHA();
        const res = await this.checkRecaptchaToken(reCAPTCHAToken, "104");
        if (!res){
          //done
          return ;
        }
        this.$store
          .dispatch("newUserRegisterRequestStore", [this.$qs.stringify(this.addUserForm), {reCAPTCHAToken: reCAPTCHAToken}])
          .then((result) => {
            localStorage.setItem(this.$common.getIpholderId("newUserTime"),JSON.stringify(Date.parse(new Date())/1000))
            this.$emit("userInterval");
            this.closeNewUser();
            this.$toast({
              message: this.$t("emsg.0002"),
              position: 'bottom',
              duration: 2000,
            })
          })
          .catch(() => {
          });
      },
      closeNewUser() {
        this.$emit('closeNewUser')
      },
      //会員規約　ｐｄｆ
      openAgreementClick() {
        window.open(this.$router.options.base+this.pdfUrl);
      },
    },
    created() {
      if (this.$route.params && this.$route.params.ipholderCode) {
        this.addUserForm.ipholderCode = this.$route.params.ipholderCode;
      }
    },
  }
</script>

<style scoped>

</style>
