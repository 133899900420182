import {default as request} from '../../utils/request'

const event = {
  state: {},
  mutations: {},
  actions: {
    //イベント一覧表示
    getEventListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/event/list",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    sendCradLikeStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/vote/vote",
          method: "post",
          data: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    getCardListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/vote/cardList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //　イベント参加をチェック追加
    joinEventCheckStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/event/joinEventCheck",
          method: "post",
          data: value,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    eventTicketListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/purchase/eventTicketList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    getHeartNumStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/heart",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
};
export default event
