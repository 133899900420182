import {default as request} from '../../utils/request'

const collection = {
  state: {},
  mutations: {},
  actions: {
    //チケット購入一覧
    getOrderedTicketListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "collection/orderedTicketList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //コレクション購入一覧
    getOrderedContentsListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/orderedContentsList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //すべて　コレクション購入一覧
    getAllContentsListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/orderedContentsAll",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //コレクション履歴
    getPartcptHistoryListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/partcptHistory",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    getOrderedContentsPhotoStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/orderedContentsPhoto",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //videomsg履歴
    orderedVideoMsgListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/orderedVideoMsgList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },

    //videomsg履歴
    orderedVideoMsgAllStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/orderedVideoMsgAll",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
//videomsg履歴
    orderedVideoMsgDetailStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/orderedVideoMsgDetail",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //videomsg履歴
    orderedVideoMsgTokenStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/orderedVideoMsgToken",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //すべて　NFT購入一覧
    getAllNftListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/orderedNftAll",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //NFT購入一覧
    getBuyNftListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/orderedNftList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },

  }
};
export default collection
