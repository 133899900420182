import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import common from './utils/common'
import timeFormat from  './utils/timeFormat'
import global from './utils/global'
import util from  './utils/util'
import validator from './utils/validator'
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/permission' // 权限
import Router from 'vue-router'
import qs from 'qs';
import { Locale } from 'vant';
import * as Fingerprint2 from 'fingerprintjs2'
// 引入模块后自动生效
import '@vant/touch-emulator';
import axios from 'axios'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import i18n from '../src/i18n/locales/index'
// For more options see below
Vue.use(VueReCaptcha, { siteKey: global.ev_variables.get(process.env.EV_TYPE).RECAPTCHA_PUBLIC_KEY })

Vue.use(Vant, {size: 'small'});

const Bus = new Vue();

Vue.config.productionTip = false;
Vue.prototype.$bus = Bus;
Vue.prototype.$qs = qs;
Vue.prototype.$timeFormat = timeFormat;
Vue.prototype.$util = util;
Vue.prototype.$common = common;
Vue.prototype.$validator = validator;
Vue.prototype.$fingerprint = Fingerprint2;
Vue.prototype.global = global;
Vue.prototype.$axios = axios;

//同じルートが異常にジャンプします。
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
window.vm= new Vue({i18n});
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  components: { App },
  template: '<App/>'
})
/***********谷歌登录自定义指令***************/
Vue.directive('google-signin-button', {
  bind: function (el, binding, vnode) {
    CheckComponentMethods()
    let clientId = binding.value
    let googleSignInAPI = document.createElement('script')
    googleSignInAPI.setAttribute('src', 'https://apis.google.com/js/api:client.js')
    document.head.appendChild(googleSignInAPI)

    googleSignInAPI.onload = InitGoogleButton

    function InitGoogleButton() {
      gapi.load('auth2', () => {
        const auth2 = gapi.auth2.init({
          client_id: clientId,
          cookiepolicy: 'single_host_origin'
        })
        auth2.attachClickHandler(el, {},
          OnSuccess,
          Onfail
        )
      })
    }
    function OnSuccess(googleUser) {
      vnode.context.OnGoogleAuthSuccess(googleUser)
      googleUser.disconnect()
    }
    function Onfail(error) {
      vnode.context.OnGoogleAuthFail(error)
    }
    function CheckComponentMethods() {
      if (!vnode.context.OnGoogleAuthSuccess) {
        throw new Error('The method OnGoogleAuthSuccess must be defined on the component')
      }

      if (!vnode.context.OnGoogleAuthFail) {
        throw new Error('The method OnGoogleAuthFail must be defined on the component')
      }
    }
  }
})
