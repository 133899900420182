<template>
  <div id="app">
    <transition name="van-fade" mode="out-in">
      <router-view :key="$route.fullPath"/>
    </transition>
    <div>
      <!--      ログイン画面-->
      <van-popup v-model="loginRelatedFlg" position="bottom"
                 :style="{ height: '100%',background:transparentFlg?'white':'#ffffffe3',}">
        <div>
          <loginPop v-if="showComponets == 'login'" ref="loginPop" @onSubmit="onSubmit"
                    @passForgetClick="passForgetClick"
                    @returnPage="returnPage" @maillVerificationClick="maillVerificationClick"
                    @addUserClick="addUserClick" @closeLoginPop="closeLoginPop" @onlyClosePop="onlyClosePop"
                    :loginReturnFlg="loginReturnFlg"></loginPop>
          <!--      パスワードリセット-->
          <passForget v-if="showComponets == 'passforget'" @countDown="countDown" :total="passwordResetInterval"
                      @closePasswordForgetPop="closePasswordForgetPop"></passForget>

          <!--      新規会員登録-->
          <addUser v-if="showComponets == 'adduser'" @addUser="addUser" @userInterval="userInterval"
                   @closeNewUser="closeNewUser"
                   :userTime="newUserInterval"></addUser>
          <!--        ログインなしはじめる-->
          <startWithout :routerName="routerName" :routerParams="routerParams" v-if="showComponets == 'startWithout'"
                        @userLoginClick="userLoginClick"
                        @closePop="closePop"></startWithout>
          <!--        チケットが必要です。-->
          <need-ticket v-if="showComponets == 'needTicket'" @onlyClosePop="onlyClosePop"></need-ticket>

          <maillVerificationPop v-if="showComponets == 'maillVerificationPop'"
                                @closePop="closePop"></maillVerificationPop>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
  import loginPop from './components/popup/login'
  import passForget from './components/popup/passwordForget'
  import addUser from './components/popup/newUser'
  import startWithout from './components/popup/startWithoutLlogin'
  import NeedTicket from "./components/popup/needTicket";
  import maillVerificationPop from './components/popup/mailboxVerificationPop'
  export default {
    name: 'App',
    data() {
      return {
        // login adduser startWithout passforget
        showComponets: "login",
        closeReturnName: '',
        transparentFlg: true,
        //login popup flg
        loginRelatedFlg: false,

        loginReturnFlg: false,

        routerName: '',
        routerParams: '',

        screenWidth: '',
        baseURL: '',
        pathName: '',
        //登录信息
        loginForm: {
          loginId: '',
          password: '',
        },
        finger: '',

        passwordResetInterval: this.global.passwordForgetTime,
        newUserInterval: this.global.newUserTime,
      }
    },
    computed: {
      //改变登录状态
      show: {
        get() {
          return this.$store.getters.isLogin
        },
        set(value) {
          this.$store.state.isLogin = false
        }
      }
    },
    components: {NeedTicket, loginPop, passForget, addUser, startWithout, maillVerificationPop},
    methods: {
      async getreCAPTCHA() {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()
        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login')
        return token;
      },
      userInterval() {
        this.clock = window.setInterval(() => {
          if (this.newUserInterval == 1) {
            this.newUserInterval = this.global.newUserTime;
            clearInterval(this.clock);
          } else {
            this.newUserInterval--;
          }
        }, 1000)
      },

      countDown() {
        this.clock = window.setInterval(() => {
          if (this.passwordResetInterval == 1) {
            this.passwordResetInterval = this.global.passwordForgetTime;
            clearInterval(this.clock);
          } else {
            this.passwordResetInterval--;
          }
        }, 1000)
      },
      onlyClosePop() {
        this.loginRelatedFlg = false;
        if (this.routerName == 'collection' || this.routerName == 'myPage') {
          this.$router.push({path: 'home', query: {seq: new Date().getTime()}})
        }
      },
      closePop() {
        this.loginRelatedFlg = false;
        let routerName = this.routerName ? this.routerName : 'home';
        this.$router.push({path: routerName, query: this.routerParams})
      },
      returnPage() {
        this.showComponets = 'startWithout';
      },
      closeNewUser() {
        this.showComponets = 'login';
      },
      closeLoginPop() {
        //ログイン成功
        this.loginRelatedFlg = false;
        this.$bus.$emit('changeLogin', false);
        this.closePop();
      },
      addUserClick() {
        this.showComponets = 'adduser';
      },
      passForgetClick() {
        this.showComponets = 'passforget';
      },
      maillVerificationClick() {
        this.showComponets = 'maillVerificationPop';
      },
      userLoginClick() {
        this.showComponets = 'login';
        this.loginReturnFlg = true;
      },
      closePasswordForgetPop() {
        this.showComponets = 'login';
      },
      async checkRecaptchaToken(values, useType) {
        let res;
        await this.$store
          .dispatch("checkRecaptchaTokenStore", {
            reCAPTCHAToken: values,
            useType: useType
          })
          .then((result) => {
            res = result.data.data.recaptchaFlg;
          })
          .catch((err) => {
            res = false;
          });
        return res;
      },
      async onSubmit(values) {
        const reCAPTCHAToken = await this.getreCAPTCHA();
        const res = await this.checkRecaptchaToken(reCAPTCHAToken, "102");
        if (!res) {
          //done
          return;
        }
        //login
        let params = {
          loginId: values.loginId,
          password: values.password,
          ipholderCode: values.ipholderCode,
        };
        this.$store
          .dispatch("Login", [this.$qs.stringify(params), {reCAPTCHAToken: reCAPTCHAToken}])
          .then((result) => {
            //ログイン成功
            //badge  取得、
            this.$store.commit("SET_BADGE");
            this.$refs.loginPop.onlyClosePop();
            this.closePop();
            this.$bus.$emit('changeLogin', false);
          })
          .catch(() => {
          });
      },
      addUser(userValues) {
        this.$store
          .dispatch("newUserLoginStore", this.$qs.stringify(userValues))
          .then((result) => {
            //ログイン成功
            this.closePop();
            this.$toast({
              message: this.$t("emsg.0016"),
              position: 'bottom',
              duration: 2000,
            })
            this.$bus.$emit('changeLogin', false);
          })
          .catch(() => {
          });
      },

      async pushRecaptcha(){
       const reCAPTCHAToken = await this.getreCAPTCHA();
       await this.checkRecaptchaToken(reCAPTCHAToken, "101");
      },

      getScrollTop() {
        var scrollTop = 0;
        if (document.documentElement && document.documentElement.scrollTop) {
          scrollTop = document.documentElement.scrollTop;
        } else if (document.body) {
          scrollTop = document.body.scrollTop;
        }
        return scrollTop;
      }
    },
    mounted() {
      var ressTime = localStorage.getItem(this.$common.getIpholderId("resetPassTime"))
      if (ressTime && JSON.parse(ressTime)) {
        var time = (Date.parse(new Date()) / 1000) - JSON.parse(ressTime);
        if (time < this.global.passwordForgetTime) {
          this.passwordResetInterval = this.global.passwordForgetTime - time;
          this.countDown();
        }
      }

      var newUserTime = localStorage.getItem(this.$common.getIpholderId("newUserTime"))
      if (newUserTime && JSON.parse(newUserTime)) {
        var times = (Date.parse(new Date()) / 1000) - JSON.parse(newUserTime);
        if (times < this.global.newUserTime) {
          this.newUserInterval = this.global.newUserTime - times;
          this.userInterval();
        }
      }

      this.$bus.$on('passResetTimeFun', val => {

      });

      this.$bus.$on('login', val => {
        this.loginRelatedFlg = true;
        this.showComponets = val.popName;
        this.routerName = val.routerPath;
        this.routerParams = val.routerParams;
        this.pushRecaptcha();

      });
      if (localStorage.getItem(this.$common.getIpholderId("ls_login_flg")) == 'true' && JSON.parse(localStorage.getItem(this.$common.getIpholderId("ls_login_flg")))) {
        //ログイン成功
        this.loginRelatedFlg = false;
        this.$bus.$emit('changeLogin', false);
        //badge  取得、
        this.$store.commit("SET_BADGE");
      }
      this.$bus.$on('closePop', val => {
        this.loginRelatedFlg = false;
      });
      let _this = this;
      window.onresize = () => {
        return (() => {
          let windowHeight = document.body.clientHeight;
          let scrollTop = _this.getScrollTop();
          let scrollHeight = document.documentElement.scrollHeight;
          if (windowHeight + scrollTop + 20 >= scrollHeight) {
            window.scrollTo(0, document.body.scrollHeight);
          }
        })()
      };
    },
    destroyed: function () {
      console.log("组件销毁", new Date())
    },
    created() {
      this.$store.commit("LANGUAGE_LANG");

      if (this.$route.params && this.$route.params.ipholderCode) {
        this.ipholderCode = this.$route.params.ipholderCode;
      }
      //preview 刷新验证/
      this.$store.commit("IS_PREVIEWFLG");
    },

  }
</script>

<style>
  #app {
    height: auto;
    min-height: 100%;
    width: 100%;
    background: #f1f1f1;
  }

  html, body {
    margin: 0;
    height: 100%;
    width: 100%;
    touch-action: manipulation;
    font-family: ヒラギノ角ゴ Pro,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,Osaka,ＭＳ Ｐゴシック,MS PGothic,sans-serif !important;
  }

  .full {
    height: calc(100vh);
  }

  .close-pop {
    width: 30px;
    height: auto;
  }
</style>
<style src="./assets/style.css" lang="css"></style>
