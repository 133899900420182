import Vue from 'vue'
import Router from 'vue-router'
import layout from '@/components/layout/Layout.vue'
import VueGtag from 'vue-gtag'

Vue.use(Router);

export const routerMap = [
  {
    path: '/404', // 該当するページが存在しない場合は404ページに遷移する
    name: '404',
    component: () => import('@/views/errorPage/404'),
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/',
    redirect: '/portal',
  },
  {
    path: '/portal',
    name: 'portal',
    component: () => import(/* webpackChunkName:'home'*/'@/components/portal'),
  },
  {
    path: '/500',
    name: '500',
    component: () => import(/* webpackChunkName:'500'*/'@/views/errorPage/500'),
  },
  {
    path: '/:ipholderCode/passReset',
    component: () => import(/* webpackChunkName:'passReset'*/'@/components/passReset'),
    name: 'passReset',
    hidden: true,
  },
  {
    path: '/:ipholderCode/newUserRequest',
    component: () => import(/* webpackChunkName:'passReset'*/'@/components/newUserRequest'),
    name: 'newUserRequest',
    hidden: true,
  },
  {
    path: '/:ipholderCode/passwordResetLogin',
    component: () => import(/* webpackChunkName:'passReset'*/'@/components/passwordResetLogin'),
    name: 'passwordResetLogin',
    hidden: true,
  },
  {
    path: '/:ipholderCode/address',
    component: () => import(/* webpackChunkName:'passReset'*/'@/components/address.vue'),
    name: 'address',
    hidden: true,
  },
  {
    path: '/:ipholderCode/changeName',
    component: () => import(/* webpackChunkName:'passReset'*/'@/components/changeName'),
    name: 'changeName',
    hidden: true,
  },
  {
    path: '/:ipholderCode/purchaseHistory',
    name: 'purchaseHistory',
    component: () => import(/* webpackChunkName:'home'*/'@/views/myPage/purchaseHistory.vue'),
  },
  {
    path: '/:ipholderCode/videoApplyDetail',
    name: '/videoApplyDetail',
    component: () => import(/* webpackChunkName:'home'*/'@/views/store/videoApply'),
  },
  {
    path: '/:ipholderCode/videoDetail',
    name: '/videoDetail',
    component: () => import(/* webpackChunkName:'home'*/'@/components/video/videoDetail'),
  },
  {
    path: '/redirect',
    component: () => import(/* webpackChunkName:'passReset'*/'@/components/redirect'),
    name: 'redirect',
    hidden: true,
  },
  {
    path: '/:ipholderCode',
    component: layout,
    hidden: true,
    redirect: '/:ipholderCode/home',
    children: [
      {
        path: '/:ipholderCode/home',
        name: 'home',
        component: () => import(/* webpackChunkName:'home'*/'@/views/home/index'),
      },
      {
        path: '/:ipholderCode/event',
        name: 'event',
        component: () => import(/* webpackChunkName:'home'*/'@/views/event/event'),
      },
      {
        path: '/:ipholderCode/myPage',
        name: 'myPage',
        component: () => import(/* webpackChunkName:'home'*/'@/views/myPage/index'),
      },
      {
        path: '/:ipholderCode/store',
        name: 'store',
        component: () => import(/* webpackChunkName:'home'*/'@/views/store/index'),
      },
      {
        path: '/:ipholderCode/storeDetails',
        name: 'storeDetails',
        component: () => import(/* webpackChunkName:'home'*/'@/views/store/details'),
      },
      {
        path: '/:ipholderCode/orderCompletion',
        name: '/orderCompletion',
        component: () => import(/* webpackChunkName:'home'*/'@/views/store/orderCompletion'),
      },
      {
        path: '/:ipholderCode/collection',
        name: 'collection',
        component: () => import(/* webpackChunkName:'home'*/'@/views/collection/index'),
      },
      {
        path: '/:ipholderCode/nftDetail',
        name: 'nftDetail',
        component: () => import(/* webpackChunkName:'nftDetail'*/'@/components/nft/nftDetail.vue'),
      },
    ]
  },
];
export default new Router({
  mode: 'history',
  base: '/',
  scrollBehavior: () => ({y: 0}),
  routes: routerMap
});

Vue.use(VueGtag, {
  config: {id: process.env.VUE_APP_GA_ID}
}, this);

