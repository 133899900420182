const formatTime = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
  
    return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
  }
  const format_time = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
  
    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
  }
  
  const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
  }
  function showMessage(error) {
    wx.showModal({
      content: error,
      showCancel: false,
    })
  }
  function showConfim(text, successHandler, cancelHandler){
    wx.showModal({
      title: '确认',
      content: text,
      success: function (res) {
        if (res.confirm) {
          if(successHandler){
            successHandler(res);
          }
        } else {
          if(cancelHandler){
            cancelHandler(res);
          }
        }
      },
  
    });
  }
  function getIndexByName(array, name){
    return array.findIndex(function (item) {
      return item.name === name;
    });
  }
  function getIndexByValue(array, value){
    return array.findIndex(function (item) {
      return item.value === value;
    });
  }
  function getBy(array ,key, value){
    return array.find(function (item) {
      return item[key] === value;
    });
  }
  function getIndexBy(array ,key, value){
    return array.findIndex(function (item) {
      return item[key] === value;
    });
  }
  function setItemValueBy(array ,searchkey, searchValue, property, value){
    if(array != undefined && array.length > 0){
      array.forEach(function (item){
        if(item[searchkey] === searchValue){
          item[property] = value;
        }
      })
    }
  }
  function isEmptyArray(array){
    return array == undefined || array == null || array.length == 0 ;
  }
  function isEmptyProperty(property){
    return property == undefined || property == null || property == ''
  }
  function deleteItemByValue(array ,key, value){
    if(key == undefined){
      return array.filter(x => x !== value) ;
    } else {
      return array.filter(x => x[key] !== value) ;
    }  
  }
  function parserDate(date) {  
    var tempStrs = date.split(" ");
    var dateStrs = tempStrs[0].split("-");
  
    var year = parseInt(dateStrs[0], 10);
  
    var month = parseInt(dateStrs[1], 10) - 1;
  
    var day = parseInt(dateStrs[2], 10);
  
    var timeStrs = tempStrs[1].split(":");
  
    var hour = parseInt(timeStrs [0], 10);
  
    var minute = parseInt(timeStrs[1], 10);
  
    var second = parseInt(timeStrs[2], 10);
  
    var date = new Date(year, month, day, hour, minute, second);
    return date;
  }
  module.exports = {
    isEmptyProperty:isEmptyProperty,
    isEmptyArray:isEmptyArray,
    formatTime: formatTime,
    parserDate:parserDate,
    format_time: format_time,
    formatNumber: formatNumber,
    showMessage: showMessage,
    getIndexByName:getIndexByName,
    getIndexByValue:getIndexByValue,
    getBy:getBy,
    getIndexBy:getIndexBy,
    deleteItemByValue:deleteItemByValue,
    setItemValueBy:setItemValueBy,
    showConfim:showConfim
  }
  