<template>
  <div style="margin-left: 15%;margin-right: 15%;">
    <div class="ta_l mt_10" style="margin-top: 30%;margin-bottom: 15%;">
      <van-image class="icon btn_return" @click="closePasswordForgetPop"
                 :src="require('../../assets/Sprint2/btn/btn_return.png')"></van-image>
    </div>
    <div class="ta_c" style="margin-top: 60%;">
      <span class="span-login-pop">{{$t('pwreset.0001')}}</span>
    </div>

    <div class="ta_c mt_10">
      <span class="span-login-pop" style="font-size: 12px;">{{$t('pwreset.0002')}}</span>
      <br>
      <span class="span-login-pop" style="font-size: 12px;">{{$t('pwreset.0003')}}</span>
    </div>

    <van-form @submit="onSubmit" style="margin-top: 15%;" show-error-message :show-error="false"
              :scroll-to-error="true">
      <div class="mt_10">
        <van-field style="border: 1px solid;" v-model="resetEmail" name="resetEmail"
                   :placeholder="$t('pwreset.0020')"
                   :rules="[{ validator }]"
                   :error-message="loginIdErrorMsg"
        />
      </div>
      <div class="mt_10" style="margin-top: 10%;">
        <van-button round block native-type="submit"
                    style="width:100%;background-image: linear-gradient(to right, #1b93a0, #78dbd6);"
                    :disabled="total != 60">
          <template v-if="total != 60">
            <span class="span-login-pop" style="color: white;">{{total +$t('pwreset.0004')}}</span>
          </template>
          <template v-else>
            <span class="span-login-pop" style="color: white;">{{$t('pwreset.0005')}}</span>
          </template>

        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  export default {
    name: "passwordForget",
    data() {
      return {
        resetEmail: "",
        loginIdErrorMsg: '',
        sendBtn: '送信',
        clock: null,
        btnDisable: false,

        ipholderCode: 0,
      }
    },
    props: ['total'],
    methods: {
      validator(val) {
        if (!val) {
          this.loginIdErrorMsg = this.$t('newuser.0005');
          return false;
        }

        if (!this.$validator.isEmail(val)) {
          this.loginIdErrorMsg = this.$t('newuser.0006');
          return false;
        }

        this.loginIdErrorMsg = '';
        return true;
      },
      closePasswordForgetPop() {
        clearInterval(this.clock);
        this.btnDisable = false;
        this.$emit('closePasswordForgetPop')
      },
      onSubmit(values) {
        this.btnDisable = true;
        var params = {
          loginId: values.resetEmail,
          ipholderCode: this.ipholderCode,
        };
        this.$store
          .dispatch("passwordResetRequestStore", this.$qs.stringify(params))
          .then((result) => {
            localStorage.setItem(this.$common.getIpholderId("resetPassTime"),JSON.stringify(Date.parse(new Date())/1000))
            this.$emit("countDown");
            this.closePasswordForgetPop();
            this.$toast({
              message: this.$t("emsg.0003"),
              position: 'bottom',
            });
          })
          .catch(() => {
            this.btnDisable = false;
          });
      },
    },
    mounted() {
    },
    created() {
      if (this.$route.params && this.$route.params.ipholderCode) {
        this.ipholderCode = this.$route.params.ipholderCode;
      }
    }
  }
</script>

<style scoped>

</style>
