import router from './router'
import {Toast} from "vant";
import store from "./store";

const whiteList = ["/home", "/story", "/login", "404", "500"];
//全局路由守卫
router.beforeEach((to, from, next) => {
  Toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick: true,
    overlay: true,
    message: 'Loading...',
  });
  //跳转路由不为空
  if (to.path) {
    if (to.path === '/login') {
      next({path: '/'})
      Toast.clear()
    } else {
      next()
      Toast.clear()
    }
  } else if (whiteList.indexOf(to.path) !== -1) {
    next()
    Toast.clear()
  } else {
    next('/login');
    Toast.clear()
  }
})
router.afterEach(() => {
  Toast.clear()
});
