<template>
  <div style="margin-left: 15%;margin-right: 15%;">
    <div class="ta_r mt_10" style="margin-top: 30%;margin-bottom: 15%;">
      <van-image class="icon close-pop" @click="closeBtn"
                 :src="require('../../assets/Sprint2/btn/btn_close.gif')"></van-image>
    </div>
    <div class="span-login-pop ta_c" style="margin-top: 80%;color: #1b93a0;">
      <span>{{$t("event.0001")}}</span><br>
      <span>{{$t("event.0002")}}</span>
    </div>
    <div style="margin-top: 10%;color: #1b93a0;">
      <van-button round block @click="buyTicket()"
                  style="width:100%;background-image: linear-gradient(to right, #1b93a0, #78dbd6);">
        <span class="span-login-pop" style="color: white;">{{$t("event.0003")}}</span>
      </van-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "needTicket",
    data() {
      return {}
    },
    methods: {
      buyTicket() {
        this.$emit('onlyClosePop')
        this.$router.push({
          path: 'store'
        })
      },
      closeBtn() {
        this.$emit('onlyClosePop')
      },
    }
  }
</script>

<style scoped>

</style>
