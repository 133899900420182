import {default as request} from '../../utils/request'
import common from '../../utils/common';

const app = {
  state: {
    pageSkip: false,//页面跳转前加的判断
    isPreviewFlg: false,
    reCAPTCHAToken: '',
    language_lang: '',
  },
  mutations: {
    SET_RECAPTCHATOKEN: (state, data) => {
      state.reCAPTCHAToken = data
    },
    SET_PAGE_SKIP: (state, data) => {
      state.pageSkip = data
    },
    LANGUAGE_LANG: (state, data) => {
      state.language_lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : 'en';
    },
    //是否跳转登录页面判断
    IS_PREVIEWFLG: (state, data) => {
      if (localStorage.getItem(common.getIpholderId("ls_preview")) == "1") {
        state.isPreviewFlg = "1"
      } else {
        state.isPreviewFlg = "0"
      }
    },
  },
  actions: {
    //地址获取
    getMasterTypeStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "master/load",
          method: "post",
          data: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    setLikeStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "like",
          method: "post",
          data: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    setWinStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "win",
          method: "post",
          data: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
  }
}
export default app
