import axios from 'axios'
import router from '../router/index'
import store from "../store";
import common from './common';
import {Toast} from 'vant';
import qs from 'qs';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_URL, // api的base_url
  timeout: 15000                  // 请求超时时间2
});

const whiteList = []; //白名单,转圈圈的路由
// request拦截器
service.interceptors.request.use(config => {

    if (config.method == 'get' && config.params) {
      if (localStorage.getItem(common.getIpholderId("ls_preview")) == "1"){
        config.params["previewFlag"] = "1";
      }
    } else {
      if (config.headers["content-type"] == "application/x-www-form-urlencoded") {
        if (config.data) {
          var postData = qs.parse(config.data);
          if (localStorage.getItem(common.getIpholderId("ls_preview")) == "1"){
            postData["previewFlag"] = "1";
            config.data = qs.stringify(postData);
          }
        }
      }
    }

    if (localStorage.getItem(common.getIpholderId("ls_token"))) {
      if (localStorage.getItem(common.getIpholderId("ls_token")) !== 'null' && localStorage.getItem(common.getIpholderId("ls_token")) != null) {
        config.headers['Authorization'] = localStorage.getItem(common.getIpholderId("ls_token")) // token
      }
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error);// for debug
    Promise.reject(error)
  }
);

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    if (response.headers.newtoken) {
      localStorage.setItem(common.getIpholderId("ls_token"), response.headers.newtoken)
    }
    const res = response;
    if (res.data.code > 0) {
      common.m_warning(res.data.msg)
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    console.log('err错误信息' + error);// for debug
    var status = error.response.status;

    if (status === 401) {
      localStorage.setItem(common.getIpholderId("ls_token"), null);
      localStorage.setItem(common.getIpholderId("ls_login_flg"), JSON.stringify(false));
      location.reload()// 为了重新实例化vue-router对象 避免bug
    } else if (status === 403) {
      if (localStorage.getItem(common.getIpholderId("ls_preview"))  == "1"){
        alert("セッションが切れました。もう一度プレヴューしてください。");
        window.location.href = "about:blank";
        window.close();
      }
      localStorage.removeItem(common.getIpholderId("ls_badge"))
      localStorage.setItem(common.getIpholderId("ls_token"), null);
      localStorage.setItem(common.getIpholderId("ls_login_flg"), JSON.stringify(false));
      store.commit("SET_BADGE");
      if (error.response.headers['login-err']) {
        var msg = error.response.headers['login-err'];
        common.errorMsg(decodeURI(msg));
      }
      router.push({path: 'home'})
    } else if (status === 404) {
      router.push({path: '/404'})
    } else if (status === 423) {
      Toast({
        message: "账号已锁定，请联系管理员！",
        type: 'error',
      })
      store.dispatch('FedLogOut').then(() => {
        location.reload()// 为了重新实例化vue-router对象 避免bug
      })
    } else if (String(status).substr(0, 1) === "5") {
      router.push({path: '/500'});
      return Promise.reject(error)
    } else {
      Toast({
        message: error.message,
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(error)
    }
  }
);

export default service

