<template>
  <div style="margin-left: 15%;margin-right: 15%;margin-top: 65%;">
    <div class="ta_c mt_10">
      <van-button round block @click="userLoginClick"
                  style="width:100%;background-image: linear-gradient(to right, #1b93a0, #78dbd6);">
        <span class="span-login-pop" style="color: white;">{{$t('login.0020')}} &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;{{$t('login.0021')}}</span>
      </van-button>
    </div>
    <div class="mt_10" style="padding-top: 2%;padding-bottom: 2%;">
      <van-divider :style="{ color: 'black', borderColor: 'black',fontWeight:'600'}">{{$t('login.0022')}}</van-divider>
    </div>
    <van-form @submit="onSubmit" show-error-message :show-error="false"
              :scroll-to-error="true">
      <div class="mt_10">
        <van-field style="border: 1px solid;" v-model="nickeName"
                   name="nickeName" :placeholder="$t('login.0023')"
                   :rules="[{ validator: nameValidator}]"
                   :error-message="nameErrorMsg"
        />
      </div>

      <div class="mt_10" style="margin-top: 15%;">
        <van-button round block native-type="submit"
                    style="width:100%;background-image: linear-gradient(to right, #1b93a0, #78dbd6);">
          <span class="span-login-pop" style="color: white;">{{$t('login.0024')}}</span>
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  export default {
    name: "startWithoutLlogin",
    data() {
      return {
        nameErrorMsg:'',
        nickeName: "",
        username: '',
        password: '',
        finger: '',
        ipholderCode:0,
      }
    },
    props: [
      'routerParams', 'routerName'
    ],
    methods: {
      nameValidator(val) {
        if (!val) {
          this.nameErrorMsg = this.$t('login.0025');
          return false;
        }
        if (val.length > 10) {
          this.nameErrorMsg = this.$t('login.0026');
          return false;
        }
        this.nameErrorMsg = '';
        return true;
      },
      onSubmit(values) {
        var params = {
          nickName: this.nickeName,
          uniqueKey: this.finger,
          ipholderCode: this.ipholderCode,
        };
        this.$store
          .dispatch("noSqlLoginStore", this.$qs.stringify(params))
          .then((result) => {
            this.$bus.$emit('changeLogin',true);
            this.$emit("closePop");
          })
          .catch(() => {
          });
      },
      userLoginClick() {
        this.$emit("userLoginClick")
      },
    },
    created() {
      if (this.$route.params && this.$route.params.ipholderCode) {
        this.ipholderCode = this.$route.params.ipholderCode;
      }
      this.$fingerprint.get((components) => {
        this.finger = this.$fingerprint.x64hash128(components.map((pair) => {
          return pair.value
        }).join(), 31)
      });
    }
  }
</script>

<style scoped>

</style>
