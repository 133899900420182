import {default as request} from '../../utils/request'
import common from '../../utils/common'

const login = {
  state: {
    m_userInfo: {},
    userType: "",
    token: "",
  },
  mutations: {
    SET_USERINFO: (state, data) => {
      state.m_userInfo = data
    },
    SET_TOKEN: (state, token) => {
      if (token) {
        state.token = token;
      } else {
        state.token = null;
        localStorage.setItem("tcb_M_token", null);
      }
    },
    //重置路由
    RESET_USER: (state) => {
      state.m_userInfo = [];
      state.isLogin = false;
      state.userType = ''
    },
  },
  actions: {
    // ログイン
    Login({commit, state}, [param1, param2]) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/login",
          method: "post",
          data: param1,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            "reCAPTCHAToken": param2.reCAPTCHAToken
          }
        }).then(data => {
          localStorage.setItem(common.getIpholderId("ls_badge"),data.data.data.notEnteredVideoMsgCount)
          localStorage.setItem(common.getIpholderId("ls_token"), data.headers.authorization);
          localStorage.setItem(common.getIpholderId("ls_info"), JSON.stringify(data.data.data));
          localStorage.setItem(common.getIpholderId("ls_name"), JSON.stringify(data.data.data.displayName));
          localStorage.setItem(common.getIpholderId("ls_login_flg"), JSON.stringify(true));
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    // ユーザー新規登録
    newUserLoginStore({commit, state}, [param1, param2]) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/newUser",
          method: "post",
          data: param1,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            "reCAPTCHAToken": param2.reCAPTCHAToken
          }
        }).then(data => {
          localStorage.setItem(common.getIpholderId("ls_badge"),data.data.data.notEnteredVideoMsgCount)
          localStorage.setItem(common.getIpholderId("ls_token"), data.headers.authorization);
          localStorage.setItem(common.getIpholderId("ls_info"), JSON.stringify(data.data.data));
          localStorage.setItem(common.getIpholderId("ls_name"), JSON.stringify(data.data.data.displayName));
          localStorage.setItem(common.getIpholderId("ls_login_flg"), JSON.stringify(true));
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    // googleログイン
    googleLoginStore({commit, state}, [param1, param2]) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/googleLogin",
          method: "post",
          data: param1,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            "reCAPTCHAToken": param2.reCAPTCHAToken
          }
        }).then(data => {
          localStorage.setItem(common.getIpholderId("ls_badge"),data.data.data.notEnteredVideoMsgCount)
          localStorage.setItem(common.getIpholderId("ls_token"), data.headers.authorization);
          localStorage.setItem(common.getIpholderId("ls_info"), JSON.stringify(data.data.data));
          localStorage.setItem(common.getIpholderId("ls_name"), JSON.stringify(data.data.data.displayName));
          localStorage.setItem(common.getIpholderId("ls_login_flg"), JSON.stringify(true));
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },

    // twitter ログイン
    twitterLoginStore({commit, state}, [param1, param2]) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/twitterLogin",
          method: "post",
          data: param1,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            "reCAPTCHAToken": param2.reCAPTCHAToken
          }
        }).then(data => {
          localStorage.setItem(common.getIpholderId("ls_badge"),data.data.data.notEnteredVideoMsgCount)
          localStorage.setItem(common.getIpholderId("ls_token"), data.headers.authorization);
          localStorage.setItem(common.getIpholderId("ls_info"), JSON.stringify(data.data.data));
          localStorage.setItem(common.getIpholderId("ls_name"), JSON.stringify(data.data.data.displayName));
          localStorage.setItem(common.getIpholderId("ls_login_flg"), JSON.stringify(true));
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },

    // パスワードリセット申請
    passwordResetRequestStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/passwordResetRequest",
          method: "post",
          data: value,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },

    //token 確認
    ckeckTokenStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/checkToken",
          method: "post",
          data: value,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },

    // パスワードリセット確認
    passwordResetStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/passwordReset",
          method: "post",
          data: value,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    // ログインなしはじめる
    noSqlLoginStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "user/trialLogin",
          method: "post",
          data: value,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        }).then(data => {
          localStorage.setItem(common.getIpholderId("ls_badge"),data.data.data.notEnteredVideoMsgCount)
          localStorage.setItem(common.getIpholderId("ls_token"), data.headers.authorization);
          localStorage.setItem(common.getIpholderId("ls_login_flg"), JSON.stringify(false));
          //页面输入的nickName
          localStorage.setItem(common.getIpholderId("ls_name"), JSON.stringify(data.data.data.displayName));
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    changePassStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/changePassword",
          method: "post",
          data: value,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        }).then(data => {
          //change pass
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    changeUserNameStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/changeUserName",
          method: "post",
          data: value,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        }).then(data => {
          //change pass
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },

    // ユーザー新規登録
    newUserRegisterRequestStore({commit, state}, [param1, param2]) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/userRegisterRequest",
          method: "post",
          data: param1,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            "reCAPTCHAToken": param2.reCAPTCHAToken
          }
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //メール承認を申請
    authMailRequestStore({commit, state}, loginForm) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/authMailRequest",
          method: "post",
          data: loginForm,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //メール承認
    authMailStore({commit, state}, loginForm) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/authMail",
          method: "post",
          data: loginForm,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        }).then(data => {
          localStorage.setItem(common.getIpholderId("ls_info"), JSON.stringify(data.data.data));
          localStorage.setItem(common.getIpholderId("ls_name"), JSON.stringify(data.data.data.displayName));
          localStorage.setItem(common.getIpholderId("ls_login_flg"), JSON.stringify(true));
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    // ログアウト
    FedLogOut({commit}) {
      return new Promise(resolve => {
        request({
          url: "logout",
          method: "post"
        }).then(data => {
          localStorage.removeItem(common.getIpholderId("ls_badge"))
          localStorage.setItem(common.getIpholderId("ls_token"), null);
          localStorage.setItem(common.getIpholderId("ls_login_flg"), JSON.stringify(false));
          localStorage.setItem(common.getIpholderId("ls_info"), JSON.stringify(null));
          localStorage.setItem(common.getIpholderId("ls_name"), JSON.stringify(null));
          resolve()
        }).catch(() => {
        })
      })
    },
    // profileを取得する。
    getUserProfileStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/profile",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 住所
    userAddressSaveStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/address/save",
          method: "post",
          data: value,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //イベント一覧表示
    checkRecaptchaTokenStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/recaptcha/siteverify",
          method: "get",
          params: value,
        }).then(data => {
          commit("SET_RECAPTCHATOKEN",value.reCAPTCHAToken)
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    getPurchaseHistoryStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/purchaseHistory",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
  }
}
export default login
