<template>
  <div>
    <van-tabbar v-model="active" :safe-area-inset-bottom="true" @change="onChange">
      <van-tabbar-item name="home">
        <template #icon="props">
          <img class="icon footerImgHeight" :src="!props.active ? icon.home : icon.homeActive"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="store">
        <template #icon="props">
          <img class="icon footerImgHeight" :src="!props.active ? icon.event : icon.eventActive"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="collection">
        <template #icon="props">
          <img class="icon footerImgHeight" :src="!props.active ? icon.store : icon.storeActive"/>
        </template>
      </van-tabbar-item>
      <template v-if="!(isPreviewFlg == 1)">
        <template v-if="badge && badge > 0 ">
          <van-tabbar-item name="myPage" :badge="badge">
            <template #icon="props">
              <img class="icon footerImgHeight" :src="!props.active ? icon.mypage : icon.mypageActive"/>
            </template>
          </van-tabbar-item>
        </template>
        <template v-else>
          <van-tabbar-item name="myPage">
            <template #icon="props">
              <img class="icon footerImgHeight" :src="!props.active ? icon.mypage : icon.mypageActive"/>
            </template>
          </van-tabbar-item>
        </template>
      </template>
    </van-tabbar>
  </div>
</template>

<script>
  export default {
    name: "Footer",
    data() {
      return {
        mypageBtnFlg: true,
        active: "home",
        icon: {
          home: null,
          homeActive: null,
          event: null,
          eventActive: null,
          store: null,
          storeActive: null,
          mypage: null,
          mypageActive: null,
        },
      }
    },
    watch: {},
    computed: {
      isPreviewFlg() {
        return this.$store.state.app.isPreviewFlg;
      },
      badge() {
        return this.$store.state.store.badge;
      },
    },
    methods: {
      onChange(index) {
        this.$nextTick(function () {
          this.active = index;
        })
        if (index == 'home') {
          this.$router.push({path: "home", query: {seq: new Date().getTime()}})
        } else if (index == 'store') {
          this.$router.push({path: "store", query: {seq: new Date().getTime()}})
        } else if (index == 'collection') {
          if (localStorage.getItem(this.$common.getIpholderId("ls_login_flg"))) {
            if (localStorage.getItem(this.$common.getIpholderId("ls_login_flg")) == 'true' || JSON.parse(localStorage.getItem(this.$common.getIpholderId("ls_login_flg")))) {
              this.$router.push({path: "collection", query: {seq: new Date().getTime()}})
              return;
            }
          }
          let params = {
            popName: 'login',
            routerPath: 'collection',
            routerParams: {seq: new Date().getTime()}
          };
          this.$bus.$emit('login', params);

        } else if (index == 'myPage') {
          if (localStorage.getItem(this.$common.getIpholderId("ls_login_flg"))) {
            if (localStorage.getItem(this.$common.getIpholderId("ls_login_flg")) == 'true' || JSON.parse(localStorage.getItem(this.$common.getIpholderId("ls_login_flg")))) {
              this.$router.push({path: "myPage", query: {seq: new Date().getTime()}})
              return;
            }
          }
          let params = {
            popName: 'login',
            routerPath: 'myPage',
            routerParams: {seq: new Date().getTime()}
          };
          this.$bus.$emit('login', params);
        }
      },
    },
    created() {
      this.active = this.$route.name;

      this.icon.home = require('../../assets/Sprint2/footer/home_' + this.$store.getters.language_lang + '.png');
      this.icon.event = require('../../assets/Sprint2/footer/store_' + this.$store.getters.language_lang + '.png');
      this.icon.store = require('../../assets/Sprint2/footer/collection_' + this.$store.getters.language_lang + '.png');
      this.icon.mypage = require('../../assets/Sprint2/footer/mypage_' + this.$store.getters.language_lang + '.png');
      this.icon.homeActive = require('../../assets/Sprint2/footer/home_on_' + this.$store.getters.language_lang + '.png');
      this.icon.eventActive = require('../../assets/Sprint2/footer/store_on_' + this.$store.getters.language_lang + '.png');
      this.icon.storeActive = require('../../assets/Sprint2/footer/collection_on_' + this.$store.getters.language_lang + '.png');
      this.icon.mypageActive = require('../../assets/Sprint2/footer/mypage_on_' + this.$store.getters.language_lang + '.png');


      if (localStorage.getItem(this.$common.getIpholderId("ls_preview")) && JSON.parse(localStorage.getItem(this.$common.getIpholderId("ls_preview"))) == true) {
        this.mypageBtnFlg = false;
      }
      ;
    },
    mounted() {
    }
  }
</script>

<style type="scss" scoped>
  .footerImgHeight {
    height: 3.5rem;
  }
</style>
