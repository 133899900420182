import {default as request} from '../../utils/request'
import common from "../../utils/common";

const storeApp = {
  state: {
    badge:0,
  },
  mutations: {
    SET_BADGE: (state, data) => {
      if (data != undefined){
        state.badge = data ? data:0;
        localStorage.setItem(common.getIpholderId("ls_badge"),data)
        return;
      }
      if (localStorage.getItem(common.getIpholderId("ls_badge"))){
        state.badge = localStorage.getItem(common.getIpholderId("ls_badge"));
      }else {
        state.badge = 0;
      }

    },
  },
  actions: {
    getTicketListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/store/ticketList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    getContentsListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/store/contentsList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    getRealGoodsStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/store/goodsList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    getVideoMsgStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/store/videomsgList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    getCallListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/store/meetingList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    productDetailsStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/store/productDetail",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    productBuyStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/purchase/productBuy",
          method: "post",
          data: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    getAccountMgmtKeyStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/vtpay/getAccountMgmtKey",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    getVideoBaseDataStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/videomsg/beforeRequestInput",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    saveVideoDataStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/videomsg/inputRequest",
          method: "post",
          data: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    getCountNotEnteredStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/user/countNotEntered",
          method: "get",
          params: value,
        }).then(data => {
          commit("SET_BADGE",data.data.data.notEnteredVideoMsgCount)
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },

    getMeetTimeListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/store/meeting/timeList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    // order status
    queryOrderStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/purchase/queryOrder",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    // cancel order
    cancelOrderStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/purchase/cancelOrder",
          method: "post",
          data: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },

    // nft list
    getNftListStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/store/nftList",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },

    // nft order nft detail
    getOrderedNftDetailStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/orderedNftDetail",
          method: "get",
          params: value,
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    //collection/savePrizeNftInfo
    savePrizeNftInfoStore({commit, state}, value) {
      return new Promise((resolve, reject) => {
        request({
          url: "/collection/savePrizeNftInfo",
          method: "post",
          data: value,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        }).then(data => {
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
  }
}
export default storeApp
